<template>
  <div class="">
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">Celebrity Styles</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of Celebrity Styles
            <span v-if="spotLight" class="tableTotal font-15 poppins"
              >(Total : {{ spotLight.length }})</span
            >
          </div>
          <div class="float-right d-flex mx-4">
            <!-- <div class="pr-6">
              <form class="search-form mr-form">
                <input
                  type="text "
                  class="form-control"
                  v-model="searchText"
                  @input="getAllSpotLights()"
                  placeholder="Search..."
                />
              </form>
            </div> -->
            <div class="float-right">
              <router-link to="/createSpotLight" class="Create-btn btn btn-success btn-small"
                >Create</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-1 text-center">#</th>

                      <th class="col-md-3">Title</th>
                      <th class="col-md-4">Mobile Image</th>
                      <th class="col-md-4">Desktop Image</th>
                      <th class="col-md-4">Link</th>
                      <th colspan="2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in spotLight" v-bind:key="item._id">
                      <td class="text-center">
                        {{ index + 1 + (page - 1) * 20 }}
                      </td>

                      <td>
                        <div>{{ item.title }}</div>
                      </td>
                      <td>
                        <div>{{ item.imageMobile }}</div>
                      </td>
                      <td>
                        <div>{{ item.image }}</div>
                      </td>
                      <td>
                        <div>{{ item.link }}</div>
                      </td>
                      <!-- v-repeat -->
                      <td>
                        <div class="d-flex">
                          <!-- <div class="mr-8">
                            <router-link
                              class="btn btn-primary"
                              :to="{
                                name: 'editSpotLight',
                                params: { id: item._id },
                              }"
                              append
                            >
                              <b-icon-pencil class="text-white"></b-icon-pencil>
                            </router-link>
                        </div> -->

                          <div class="mr-8">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="green"
                                  :to="{
                                    name: 'editSpotLight',
                                    params: { id: item._id },
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                  append
                                >
                                  <b-icon-pencil class="text-white"></b-icon-pencil>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                          </div>

                          <!-- <div class="deletedModal mr-8">
                            <b-button
                              variant="danger"
                              v-b-modal.delete-modal
                              @click="deletSpotLightById(item._id)"
                            >
                              <b-icon-trash></b-icon-trash>
                            </b-button>
                        </div> -->

                          <div class="mr-8">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-b-modal.delete-modal
                                  @click="deleteConfirm(item._id)"
                                >
                                  <b-icon-trash color="white"></b-icon-trash>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <div></div>
                    <!-- end v-repeat -->
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="!spotLight"
              >
                No Data Found
              </div>

              <!-- Use text in props -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4" v-if="spotLight">
      <v-pagination
        class="mb-2 float-center"
        v-model="page"
        :length="totalPages"
        prev-icon="<"
        next-icon=">"
        :per-page="20"
        @input="getAllSpotLights()"
      />
      <!-- <b-pagination
        class="mb-2 float-center justify-center"
        v-model="page"
        :total-rows="totalPages"
        :per-page="20"
        @input="getAllSpotLights()"
      /> -->
    </div>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import homeService from '../../services/homeService';
import ConfirmDlg from '../../components/ConfirmDlg.vue';
export default {
  components: { ConfirmDlg },
  data() {
    return {
      searchText: '',
      spotLight: [
        {
          name: 'User 1',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 2',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 3',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
      ],
      page: 1,
      perPage: 10,
      errors: [],
      totalPages: 1,
      dialog: false,
      items: ['Cart', 'Orders', 'sizes', 'Addresses', 'Wishlist', 'Saved Looks', 'Stylist Assists'],
    };
  },
  created() {
    this.getAllSpotLights();
  },
  methods: {
    async getAllSpotLights() {
      const data = {};
      data.page = this.page;
      const result = await homeService.getAllSpotLights(data);
      if (result.status == 200) {
        this.spotLight = result.data.data;
        this.totalPages = result.data.paginationData.totalPages;
      }
    },
    async deletSpotLightById(id) {
      const result = await homeService.deleteSpotLight(id);

      if (result.status === 200) {
        this.$store.commit('setSnackbar', {
          content: 'Deleted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
      this.$router.go();
      console.log('result', this.spotLight);
    },
    async deleteConfirm(id) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.deletSpotLightById(id);
        console.log('got confirmation');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  width: 140px;
  max-height: 60px;
  font-size: 11px;
}
.Create-btn {
  color: white !important;
}
</style>
